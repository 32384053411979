import { useEffect, useState } from 'react';

import { getPricingTrimRule, TRIM_RULES } from 'Utils/currencyUtils';

import { ICurrency } from 'Constants/currency';

const usePricingTrimingRule = (
	inventoryListsMap: Map<String, any>,
	currVisibleMonth: string | null,
	nextVisibleMonth: string | null,
	currenciesMap: ICurrency[],
) => {
	const [pricingTrimRule, setPricingTrimRule] = useState<
		TRIM_RULES | undefined
	>(undefined);
	useEffect(() => {
		if (currVisibleMonth && !pricingTrimRule) {
			let inventoryToCheck = inventoryListsMap.get(currVisibleMonth);
			if (nextVisibleMonth && inventoryToCheck) {
				let nextMonthInventory =
					inventoryListsMap.get(nextVisibleMonth);
				if (nextMonthInventory?.length > 0) {
					inventoryToCheck = [
						...inventoryToCheck,
						...nextMonthInventory,
					];
				}
			}
			if (inventoryToCheck) {
				const currentMonthPricesToShow = inventoryToCheck.reduce(
					(
						currentMonthPrices: {
							price: number;
							currency: ICurrency;
						}[],
						inventory: any,
					) => {
						const priceForDate = inventory.priceTag;
						if (priceForDate) {
							currentMonthPrices.push({
								price: priceForDate.price,
								currency:
									currenciesMap?.[priceForDate.currency],
							});
						}
						return currentMonthPrices;
					},
					[],
				);
				const trimRule = getPricingTrimRule(currentMonthPricesToShow);
				setPricingTrimRule(trimRule);
			} else {
				setPricingTrimRule(TRIM_RULES.NONE);
			}
		}
	}, [currVisibleMonth, pricingTrimRule]);
	return pricingTrimRule;
};

export default usePricingTrimingRule;

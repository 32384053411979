import * as React from 'react';

export const PromoIcon = (props: any) => {
	return (
		<svg
			width='12'
			height='12'
			viewBox='0 0 12 12'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M6.125 1.27539L7.67 4.40539L11.125 4.91039L8.625 7.34539L9.215 10.7854L6.125 9.16039L3.035 10.7854L3.625 7.34539L1.125 4.91039L4.58 4.40539L6.125 1.27539Z'
				fill='#FF007A'
			/>
		</svg>
	);
};

import React from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

/* Components, Assets */
import Modal from 'Components/common/modalRevamped';

import { RightArrowSvgThicker } from 'Assets/svg/productPage';

import { CALENDAR_CONSTS } from 'Constants/constants';

/* Colors */
import colors from 'Static/typography/colors';

const {
	CAL_TYPES,
	SEVEN_DAY_CAL: { MAX_MONTHS_IN_A_COL },
	REGULAR_DUMMY_CAL_ROWS_COUNT,
} = CALENDAR_CONSTS;

const CalendarContainer = styled.div`
	background: ${colors.WHITE};
	border-radius: 0.5rem;
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 2px 8px rgba(0, 0, 0, 0.1);
	border: 1px solid ${colors.GREY['EB']};

	.calendar-content {
		display: flex;
	}
`;

const MonthWrapper = styled.div``;

const TopBar = styled.div<{ isSingleMonth?: boolean; isSecondMonth?: boolean }>`
	padding: ${({ isSingleMonth, isSecondMonth }) =>
		isSingleMonth
			? '1rem 2.75rem 0.75rem'
			: isSecondMonth
			? '1rem 2.75rem 0.75rem 1.25rem'
			: '1rem 1.25rem 0.75rem 2.75rem'};
	border-bottom: 1px solid ${colors.GREY_DS.G6};
	position: relative;

	.month-name {
		margin-left: 1rem;
		height: 1.75rem;
		width: 10rem;
		background: ${colors.GREY_DS.G8};
	}

	.week-days {
		margin-top: 0.75rem;
		background: ${colors.GREY_DS.G8};
		width: 26.25rem;
		height: 1.25rem;
	}

	.arrow {
		position: absolute;
		top: 1.5rem;
		right: 1rem;

		&.arrow-left {
			left: 1rem;
			right: unset;
			transform: rotate(180deg);
		}
	}
`;

const CalendarBody = styled.div<{
	isSingleMonth?: boolean;
	isSecondMonth?: boolean;
}>`
	padding: ${({ isSingleMonth, isSecondMonth }) =>
		isSingleMonth
			? '1.375rem 2.75rem 1.5rem'
			: isSecondMonth
			? '1.375rem 2.75rem 1.5rem 1.25rem'
			: '1.375rem 1.25rem 1.5rem 2.75rem'};

	.date-row {
		width: 26.25rem;
		height: 3.75rem;
		margin-top: 0.25rem;
		border-radius: 0.25rem;
		background: ${colors.GREY_DS.G8};

		&.row-1,
		&.row-5 {
			width: 18.75rem;
		}

		&.row-1 {
			margin-left: 7.5rem;
		}
	}
`;

const FootNote = styled.div`
	margin: 0 2.75rem;
	padding: 0.75rem 1rem 1rem;
	border-top: 1px dashed ${colors.GREY_DS.G6};

	.footnote-content {
		background: ${colors.GREY_DS.G8};
		height: 1rem;
		width: 15.375rem;
	}
`;

const ColumnWrapper = styled.div``;

const MonthRow = styled.div<{
	isFirst?: boolean;
	isFirstCol?: boolean;
	hasTwoCol?: boolean;
}>`
	padding: ${({ hasTwoCol, isFirstCol }) =>
		!hasTwoCol
			? '1rem 2.75rem'
			: isFirstCol
			? '1rem 1.25rem 1rem 2.75rem'
			: '1rem 2.75rem 1rem 1.25rem'};
	border-top: ${({ isFirst }) =>
		isFirst ? 'none' : `1px solid ${colors.GREY_DS.G6}`};

	.month-name {
		margin-left: 1rem;
		width: 10rem;
		height: 1.75rem;
		background: ${colors.GREY_DS.G8};
	}

	.month-dates {
		margin-top: 1.25rem;
		width: 26.25rem;
		height: 5.5rem;
		background: ${colors.GREY_DS.G8};
	}
`;

const CalendarWrapper = styled.div`
	position: absolute;
	margin-top: 0.25rem;
	background: ${colors.WHITE};
	border: 1px solid ${colors.GREY_DS.G6};
	border-radius: 0.25rem;
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 2px 8px rgba(0, 0, 0, 0.1);
	z-index: 1;
	width: 15.125rem;
`;

const StyledDate = styled.div`
	height: 2.625rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.75rem;

	.formatted-date {
		width: 5.25rem;
		height: 1rem;
		background: ${colors.GREY_DS.G8};
	}

	.price {
		margin-left: 2rem;
		width: 6.375rem;
		height: 1.125rem;
		background: ${colors.GREY_DS.G8};
	}
`;

const DummyCalendar = ({
	type,
	inventoryListsMap,
	wrapperClass,
	selfRef,
	allowPageScroll,
}: any) => {
	const getDateListCalendar = () => {
		return (
			<CalendarWrapper ref={selfRef} className={wrapperClass}>
				{Array(6)
					// @ts-expect-error TS(2554): Expected 1-3 arguments, but got 0.
					.fill()
					// @ts-expect-error TS(6133): 'x' is declared but its value is never read.
					.map((x, index) => (
						<StyledDate key={index}>
							<div className='formatted-date' />
							<div className='price' />
						</StyledDate>
					))}
			</CalendarWrapper>
		);
	};

	const getSevenDaysCalendar = () => {
		const isSevenDaysWideCalendar =
			[...inventoryListsMap.keys()].length > MAX_MONTHS_IN_A_COL
				? true
				: false;

		const getMonth = (isFirst: any, isFirstCol: any, hasTwoCol: any) => (
			<MonthRow
				isFirst={isFirst}
				isFirstCol={isFirstCol}
				hasTwoCol={hasTwoCol}
			>
				<div className='month-name' />
				<div className='month-dates' />
			</MonthRow>
		);

		const getColumnWrapper = (isFirstCol: any, hasTwoCol: any) => (
			<ColumnWrapper>
				{Array(MAX_MONTHS_IN_A_COL)
					// @ts-expect-error TS(2554): Expected 1-3 arguments, but got 0.
					.fill()
					// @ts-expect-error TS(6133): 'x' is declared but its value is never read.
					.map((x, index) =>
						getMonth(index === 0, isFirstCol, hasTwoCol),
					)}
			</ColumnWrapper>
		);

		return (
			<Modal
				open={true}
				containerClassName={'calendar-modal-container'}
				contentClassName={'calendar-content-wrapper'}
				allowPageScroll={allowPageScroll}
			>
				<CalendarContainer ref={selfRef} className={wrapperClass}>
					<div className='calendar-content'>
						{getColumnWrapper(true, isSevenDaysWideCalendar)}
						{isSevenDaysWideCalendar &&
							getColumnWrapper(false, true)}
					</div>
					<FootNote>
						<div className='footnote-content'></div>
					</FootNote>
				</CalendarContainer>
			</Modal>
		);
	};

	const getRegularCalendar = () => {
		const isSingleMonth = [...inventoryListsMap.keys()].length === 1;

		// @ts-expect-error TS(7006): Parameter 'isSingleMonth' implicitly has an 'any' ... Remove this comment to see the full error message
		const getMonthWrapper = (isSingleMonth, isSecondMonth = false) => (
			<MonthWrapper>
				<TopBar
					isSingleMonth={isSingleMonth}
					isSecondMonth={isSecondMonth}
				>
					{!isSingleMonth && !isSecondMonth && (
						<div className='arrow arrow-left'>
							<RightArrowSvgThicker />
						</div>
					)}
					<div className='month-name' />
					<div className='week-days' />
					{isSecondMonth && (
						<div className='arrow arrow-right'>
							<RightArrowSvgThicker />
						</div>
					)}
				</TopBar>
				<CalendarBody
					isSingleMonth={isSingleMonth}
					isSecondMonth={isSecondMonth}
				>
					{Array(REGULAR_DUMMY_CAL_ROWS_COUNT)
						// @ts-expect-error TS(2554): Expected 1-3 arguments, but got 0.
						.fill()
						.map((_, i) => (
							<div key={i} className={`date-row row-${i + 1}`} />
						))}
				</CalendarBody>
			</MonthWrapper>
		);

		return (
			<Modal
				open={true}
				containerClassName={'calendar-modal-container'}
				contentClassName={'calendar-content-wrapper'}
				allowPageScroll={allowPageScroll}
			>
				<CalendarContainer ref={selfRef} className={wrapperClass}>
					<div className='calendar-content'>
						{getMonthWrapper(isSingleMonth)}
						{!isSingleMonth && getMonthWrapper(isSingleMonth, true)}
					</div>
					<FootNote>
						<div className='footnote-content'></div>
					</FootNote>
				</CalendarContainer>
			</Modal>
		);
	};

	const getCalendarComponent = () => {
		switch (type) {
			case CAL_TYPES.DATE_LIST_CALENDAR:
				return getDateListCalendar();
			case CAL_TYPES.SEVEN_DAY_CALENDAR:
				return getSevenDaysCalendar();
			default:
				return getRegularCalendar();
		}
	};

	return getCalendarComponent();
};

export default DummyCalendar;

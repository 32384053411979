import { notify } from 'Components/common/notify';

import fetch from 'Utils/fetchWrapper';
import { read, write } from 'Utils/localStorageUtils';
import { error } from 'Utils/logUtils';
import { cleanSeatMapSvg } from 'Utils/svgUtils';

import { decrementAPICount, incrementAPICount } from 'Actions/apiCount';
import { receiveSeatingSVG, requestSeatingSVG } from 'Actions/booking';
import { setAPIServerAPIStatus } from 'Actions/serverStatus';

import { DATA_SOURCE_TYPE, STORE_ENTITY_TYPE } from 'Constants/constants';

export const fetchSeatingSVG =
	({ id, svgUrl }: any) =>
	(dispatch: any) => {
		dispatch(requestSeatingSVG(id));

		let storeResponseAvailable = false;

		const svgData = read(`${STORE_ENTITY_TYPE.SEAT_SVG}${id}`);
		if (svgData) {
			dispatch(
				receiveSeatingSVG({
					id,
					svg: svgData,
					dataSource: DATA_SOURCE_TYPE.STORE,
				}),
			);
			storeResponseAvailable = true;
		}

		if (!storeResponseAvailable) {
			dispatch(incrementAPICount());
		}

		let url = svgUrl.replace(
			's3.amazonaws.com/tourlandish/',
			'cdn-s3.headout.com/',
		);
		if (url.startsWith('//')) {
			url = `https:${url}`;
		}
		// NOTE: need to send request in this mode to allow s3 cors request
		return fetch(url, { credentials: 'same-origin' })
			.then(response => response.text())
			.then(text => {
				write(`${STORE_ENTITY_TYPE.SEAT_SVG}${id}`, text);
				if (!storeResponseAvailable) {
					dispatch(decrementAPICount());
				}
				dispatch(receiveSeatingSVG({ id, svg: cleanSeatMapSvg(text) }));
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				if (!storeResponseAvailable) {
					dispatch(decrementAPICount());
				}
				notify.showNetworkError(err);
			});
	};

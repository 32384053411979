import {
	getBooking as getBookingFromBookingStore,
	getCartItemBookingEquivalent,
	getCartItemSelectedDate,
	getSelectedDate as getBookingSelectedDate,
} from 'Utils/stateUtils';

import {
	setBookingDate as setBookingStoreDate,
	setBookingPeople as setBookingStorePeople,
	setBookingTourId as setBookingStoreTourId,
	setBookingVariantId as setBookingStoreVariantId,
} from 'Actions/booking';
import {
	setCartItemTourId,
	setCartSelectionItemDate,
	setCartSelectionItemPax,
	setCartSelectionItemVariantId,
} from 'Actions/cart';
import { reducers } from 'Reducers/rootReducer';

import { IPeople } from './types';

export const getBooking = (
	state: any,
	id: string,
	isCrossSell: boolean = false,
) => {
	if (isCrossSell) {
		return getCartItemBookingEquivalent(state, id);
	}

	return getBookingFromBookingStore(state, id);
};

export const getSelectedDate = (
	state: {
		[key: keyof typeof reducers]: any;
	},
	id: string | number,
	isCrossSell: boolean = false,
) => {
	return isCrossSell
		? getCartItemSelectedDate(state, id)
		: getBookingSelectedDate(state, id);
};

export const setBookingPeople = (
	{ id, pricing, people }: { id: string; pricing: any; people: IPeople },
	isCrossSell: boolean = false,
) => {
	if (isCrossSell) {
		const { selectionMap, groupSize } = people ?? {};
		return setCartSelectionItemPax({
			selectionMap,
			groupSize,
			id,
			pricing,
		});
	}

	return setBookingStorePeople({ id, pricing, people });
};

export const setBookingDate = (
	{
		id,
		date,
		defaultSelection = false,
	}: {
		id: string;
		date: string;
		defaultSelection: boolean;
	},
	changeQueryDate: boolean = true,
	isCrossSell: boolean = false,
) => {
	const argsObject = {
		id,
		date,
		defaultSelection,
	};

	return isCrossSell
		? setCartSelectionItemDate(argsObject)
		: setBookingStoreDate(argsObject, changeQueryDate);
};

export const setBookingTourId = (
	{
		id,
		tourId,
		defaultSelection,
	}: {
		id: string;
		tourId: number;
		defaultSelection?: boolean;
	},
	changeQuery: boolean = true,
	isCrossSell: boolean = false,
) => {
	if (isCrossSell) {
		return setCartItemTourId({ id, tourId, defaultSelection });
	}

	return setBookingStoreTourId({ id, tourId, defaultSelection }, changeQuery);
};

export const setBookingVariantId = (
	{
		id,
		variantId,
		defaultSelection,
	}: { id: string; variantId: number; defaultSelection?: boolean },
	changeQuery: boolean = true,
	isCrossSell: boolean = false,
) => {
	if (isCrossSell) {
		return setCartSelectionItemVariantId({
			id,
			variantId,
			defaultSelection,
		});
	}

	return setBookingStoreVariantId(
		{ id, variantId, defaultSelection },
		changeQuery,
	);
};

/**
 * Removes width and height attributes on the svg tag
 * @param svg
 * @returns string
 */
export const cleanSeatMapSvg = (svg: any) => {
	let cleanSvg = svg;
	if (/<svg[^>]*width=("|')[0-9a-z.]*('|").*>/.test(svg))
		cleanSvg = cleanSvg.replace(/width=("|')[0-9a-z.]*('|")/, '');
	if (/<svg[^>]*height=("|')[0-9a-z.]*('|").*>/.test(svg))
		cleanSvg = cleanSvg.replace(/height=("|')[0-9a-z.]*('|")/, '');
	return cleanSvg;
};

export const filterAndRemovePolygons = (
	svgString: string,
	selectedClasses: string[],
) => {
	const parser = new DOMParser();
	const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
	const svgElement = svgDoc.documentElement;

	const selectedGroup = svgElement.querySelector(
		`.tour-sections`,
	) as SVGPolygonElement;

	if (!selectedGroup) return;

	const allPolygons = selectedGroup.querySelectorAll('polygon');
	allPolygons.forEach(polygon => {
		const polygonClass = polygon.getAttribute('class') as string;
		if (!selectedClasses.includes(polygonClass)) {
			polygon.remove();
		}
	});

	const serializer = new XMLSerializer();
	return serializer.serializeToString(svgElement);
};

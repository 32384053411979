import { getCalendarInventories } from 'Utils/stateUtils';

import { ICurrency } from 'Constants/currency';

export const getPriceTagFromCalendarInv = (inventory: any, currency: any) => {
	if (!inventory) return null;

	const { listingPrice: price, originalPrice } = inventory;
	const discount = originalPrice > price ? originalPrice - price : 0;
	const discountInPercent = discount ? (discount / originalPrice) * 100 : 0;
	return {
		price,
		originalPrice,
		discount,
		discountInPercent,
		currency,
	};
};

export const getPricesToDisplay = (
	inventoryData: any,
	datesToDisplay: any,
	currenciesMap: any,
) => {
	return datesToDisplay?.reduce(
		(
			pricesToDisplay: { price: number; currency: ICurrency }[],
			date: string,
		) => {
			const priceForDate = getPriceTagFromCalendarInv(
				inventoryData.inventoryMap[date],
				inventoryData.currency,
			);
			if (priceForDate) {
				pricesToDisplay.push({
					price: priceForDate.price,
					currency: currenciesMap?.[inventoryData.currency],
				});
			}
			return pricesToDisplay;
		},
		[],
	);
};

// @ts-expect-error TS(7031): Binding element 'state' implicitly has an 'any' ty... Remove this comment to see the full error message
export const isCalendarDataAvailable = ({ state, id, tourIds = [] }) => {
	let isAvailable = true;
	if (tourIds.length > 1) {
		tourIds.forEach(tourId => {
			const invData = getCalendarInventories(state, id, tourId);
			if (!invData) {
				isAvailable = false;
			}
		});
	} else {
		const invData = getCalendarInventories(state, id);
		if (!invData) {
			isAvailable = false;
		}
	}
	return isAvailable;
};

export const getCalendarFetchStatus = ({
	state,
	tourId,
	id,
}: {
	tourId?: number;
	id: number;
	state: any;
}) => {
	if (tourId && id) {
		return state?.calendarInvsStore?.isFetching[id]?.[tourId];
	}

	return state?.calendarInvsStore?.isFetching[id];
};

export const getParallelCalendarFetchStatus = ({
	state,
	tourId,
	id,
}: {
	tourId?: number;
	id: number;
	state: any;
}) => {
	if (tourId && id) {
		return Boolean(state?.calendarInvsStore?.isFetching[id]?.[tourId]);
	}

	/**
	 * Even if one key in the isFetching?.[id] is true we return true
	 * { id: false } | { id: { tid: false} } both of them give varied results causing issue for combo fetching states
	 */
	return typeof state?.calendarInvsStore?.isFetching[id] === 'object'
		? Object.values(state?.calendarInvsStore?.isFetching[id]).some(
				value => value === true,
		  )
		: Boolean(state?.calendarInvsStore?.isFetching[id]);
};

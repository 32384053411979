import React, { useEffect } from 'react';
import { connect } from 'react-redux';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import DateBig from 'Containers/common/dateBig';

import LSpan from 'Components/common/localizedTags/localizedSpan';
/* Components, Containers */
import Modal from 'Components/common/modalRevamped';
import DummyCalendar from 'Components/desktop/dummyCalendar';

import { RightArrowSvgThicker } from 'Assets/svg/productPage';

import usePricingTrimingRule from 'Hooks/usePriceTrimingRule';
import { getPriceFormatWithTrimRule } from 'Utils/currencyUtils';
import { format, formatInMonthTitleFormat } from 'Utils/dateUtils';
import dayjs from 'Utils/dayjsUtil';
import PlatformUtils from 'Utils/platformUtils';
import {
	getCitiesMap,
	getCurrenciesMap,
	getCurrentCityCode,
	getCurrentCurrency,
} from 'Utils/stateUtils';
import { getRemValueForAllScreenSizes } from 'Utils/stylingUtils';

/* colors, typographies, constants */
import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

const ModalWrapper = styled.div``;

export const CalendarWrapper = styled.div`
	background: ${colors.WHITE};
	border-radius: 0.5rem;
	border: 1px solid ${colors.GREY['EB']};

	${({
		// @ts-expect-error TS(2339): Property 'isDesktop' does not exist on type 'Pick<... Remove this comment to see the full error message
		isDesktop,
	}) =>
		isDesktop
			? 'display: inline-block;width: 31.75rem;'
			: 'width:calc(100% - 13px);margin:0 auto;display:block;'}
`;
const CalendarContainer = styled.div`
	display: flex;
	${({
		// @ts-expect-error TS(2339): Property 'isDesktop' does not exist on type 'Pick<... Remove this comment to see the full error message
		isDesktop,
	}) => {
		const remVal = getRemValueForAllScreenSizes(24, isDesktop);
		if (typeof remVal === 'number') return `margin-bottom:${remVal}rem;`;
		else {
			let style = '';
			Object.keys(remVal).map((minResolution, index) => {
				if (index === 0) {
					style += `margin-bottom:${remVal[minResolution]}rem;`;
				}
				style += `@media (min-width: ${minResolution}px){
					margin-bottom:${remVal[minResolution]}rem;
				}`;
			});
			return style;
		}
	}};
`;

const TopBar = styled.div`
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid ${colors.GREY_DS.G6};
	padding-top: ${({
		// @ts-expect-error TS(2339): Property 'isDesktop' does not exist on type 'Pick<... Remove this comment to see the full error message
		isDesktop,
	}) => (isDesktop ? `1rem` : `1.5456rem`)};
	${({
		// @ts-expect-error TS(2339): Property 'isDesktop' does not exist on type 'Pick<... Remove this comment to see the full error message
		isDesktop,
	}) => !isDesktop && `background-color: ${colors.GREY_DS.G8};`}
	.day-list-container-dual-month {
		margin: ${({
			// @ts-expect-error TS(2339): Property 'isDesktop' does not exist on type 'Pick<... Remove this comment to see the full error message
			isDesktop,
		}) => (isDesktop ? `0.75rem 2.75rem` : `1.0306rem 0.75rem 0.7775rem`)};

		.day-list {
			display: flex;

			.day-wrapper span {
				${getFontDetailsByLabel(TYPE_LABELS.HEADING_XS)}
				width: ${({
					// @ts-expect-error TS(2339): Property 'isDesktop' does not exist on type 'Pick<... Remove this comment to see the full error message
					isDesktop,
				}) => (isDesktop ? `3.75rem` : `3rem`)};
				display: inline-flex;
				justify-content: center;
				color: ${colors.GREY_DS.G4};
				user-select: none;
			}
		}
	}
`;

const MonthWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	.calendar-body-wrapper {
		display: flex;
		height: 100%;
		margin-top: ${({
			// @ts-expect-error TS(2339): Property 'isDesktop' does not exist on type 'Pick<... Remove this comment to see the full error message
			isDesktop,
		}) => (isDesktop ? `1.5rem` : `0.5rem`)};
	}
`;

const CalendarBody = styled.div`
	width: ${({
		// @ts-expect-error TS(2339): Property 'isDesktop' does not exist on type 'Pick<... Remove this comment to see the full error message
		isDesktop,
	}) =>
		isDesktop ? `26.25rem` : `21.25rem`}; // 7 blocks of 3.75rem in a row
	box-sizing: content-box;
`;

const DateComponentsWrapper = styled.div`
	display: flex;
	flex-flow: row wrap;

	> div {
		margin-top: 0.25rem;
	}
`;

const MonthTitle = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;

	.monthName {
		${({
			// @ts-expect-error TS(2339): Property 'isDesktop' does not exist on type 'Pick<... Remove this comment to see the full error message
			isDesktop,
		}) =>
			isDesktop
				? getFontDetailsByLabel(TYPE_LABELS.HEADING_REGULAR)
				: getFontDetailsByLabel(TYPE_LABELS.HEADING_SMALL)};
		color: ${colors.GREY_DS.G2};
		text-transform: capitalize;
		margin: 0 2.5rem 0 2.25rem;
		user-select: none;

		&.first {
			${({
				// @ts-expect-error TS(2339): Property 'isDesktop' does not exist on type 'Pick<... Remove this comment to see the full error message
				isDesktop,
			}) => (isDesktop ? 'margin-left: 3.75rem;' : 'margin: 0 auto;')}
		}
	}

	.chevron-icon {
		position: absolute;
		cursor: pointer;

		&.scroll-left {
			left: 1rem;
			transform: rotate(180deg);
		}

		&.scroll-right {
			right: ${({
				// @ts-expect-error TS(2339): Property 'isDesktop' does not exist on type 'Pick<... Remove this comment to see the full error message
				isDesktop,
			}) => (isDesktop ? `1rem` : `1.6769rem`)};
		}
	}
`;

const StyledFootNote = styled.div`
	border-top: 1px dashed ${colors.GREY_DS.G6};
	padding-top: 0.75rem;
	margin: 0 2.75rem 1rem;

	.calendar-footnote {
		${getFontDetailsByLabel(TYPE_LABELS.SUBHEADING_XS)};
		color: ${colors.GREY_DS.G3};
	}
`;

const CalendarBodyWrapper = styled.div`
	justify-content: center;
`;

const ArrowButton = styled.div`
	path {
		${({
			// @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'Pick<D... Remove this comment to see the full error message
			disabled,
		}) =>
			disabled ? `stroke: ${colors.GREY['LIGHTEST']} !important;` : ''}
	}
	${({
		// @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'Pick<D... Remove this comment to see the full error message
		disabled,
	}) => (disabled ? `pointer-events:none;` : '')}
	color: ${colors.GREY['88']};
`;

const MonthScrollDir = { LEFT: -1, RIGHT: +1 };

const RegularCalendarForBroadway = ({
	selfRef,
	inventoryListsMap,
	medianPrice,
	selectedDate,
	onDateSelected,
	wrapperClass,
	hidePrice,
	id,
	type,
	sendCalendarOpenedEvent,
	currencyCode,
	currenciesMap,
	currentCityCode,
	citiesMap,
	sendNextClickedEvent,
	isStaticPositioned,
}: any) => {
	const [currVisibleMonth, setCurrVisibleMonth] = React.useState(null);
	const [nextVisibleMonth, setNextVisibleMonth] = React.useState(null);
	const pricingTrimRule = usePricingTrimingRule(
		inventoryListsMap,
		currVisibleMonth,
		nextVisibleMonth,
		currenciesMap,
	);
	const isDesktop = PlatformUtils.isDesktop();

	const onMonthChange = (direction: any) => {
		const months = [...inventoryListsMap.keys()];
		const currIndex = months.indexOf(currVisibleMonth),
			nextIndex = nextVisibleMonth
				? months.indexOf(nextVisibleMonth)
				: null;
		if (currIndex < 0 || currIndex >= (months as any).size) {
			return;
		}
		setCurrVisibleMonth(months[currIndex + direction]);
		setNextVisibleMonth(nextIndex ? months[nextIndex + direction] : null);
		sendNextClickedEvent(type, direction);
	};

	const getDaysDiv = () => {
		const dayInitials = dayjs.weekdaysShort();
		const divArray = dayInitials.map((day, index) => (
			// @ts-expect-error TS(2322): Type '{ children: Element; className: string; key:... Remove this comment to see the full error message
			<div className='day-wrapper' key={index} isDesktop={isDesktop}>
				<LSpan>{day}</LSpan>
			</div>
		));
		return (
			<div className='day-list-container-dual-month'>
				<div className='day-list'>{divArray}</div>
			</div>
		);
	};

	const getMonthHeader = (isSecondMonth: any) => {
		const currMonthIndex = [...inventoryListsMap.keys()].indexOf(
			currVisibleMonth,
		);
		const leftButton = (
			<ArrowButton
				className='chevron-icon scroll-left'
				// @ts-expect-error TS(2769): No overload matches this call.
				disabled={currMonthIndex <= 0}
				onClick={() => onMonthChange(MonthScrollDir.LEFT)}
				role='button'
				tabIndex={0}
				aria-label='Previous Month'
			>
				<RightArrowSvgThicker />
			</ArrowButton>
		);
		const rightButton = (
			<ArrowButton
				className='chevron-icon scroll-right'
				// @ts-expect-error TS(2769): No overload matches this call.
				disabled={currMonthIndex >= inventoryListsMap.size - 1}
				onClick={() => onMonthChange(MonthScrollDir.RIGHT)}
				role='button'
				tabIndex={0}
				aria-label='Next Month'
			>
				<RightArrowSvgThicker />
			</ArrowButton>
		);
		return (
			// @ts-expect-error TS(2769): No overload matches this call.
			<MonthTitle isSecondMonth={isSecondMonth} isDesktop={isDesktop}>
				{leftButton}
				{!isSecondMonth ? (
					<span className='monthName first'>{currVisibleMonth}</span>
				) : null}
				{nextVisibleMonth && isSecondMonth ? (
					<span className='monthName second'>{nextVisibleMonth}</span>
				) : null}
				{rightButton}
			</MonthTitle>
		);
	};

	const getDateComponents = (isSecondMonth: any) => (
		<DateComponentsWrapper>
			{inventoryListsMap
				.get(isSecondMonth ? nextVisibleMonth : currVisibleMonth)
				.map((invDetails: any) => {
					const { priceTag, date } = invDetails;
					const dateAsString = String(new Date(date).getUTCDate());
					const selectedDateAsString = String(selectedDate);
					const fullDateAsString = format(date, 'yyyy-mm-dd', true);
					let displayCurrency,
						displayPrice,
						isMinPriceDate = false;

					if (priceTag) {
						const { price, currency } = priceTag;
						displayCurrency = currency;
						displayPrice = getPriceFormatWithTrimRule(
							price,
							currenciesMap?.[priceTag.currency],
							pricingTrimRule,
						);
						isMinPriceDate = price <= medianPrice;
					}

					return (
						<DateBig
							isAvailable={invDetails.isAvailable}
							isEmpty={invDetails.isEmpty}
							key={invDetails.key}
							dateAsString={dateAsString}
							price={displayPrice}
							// @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
							medianPrice={medianPrice}
							unavailableText={''}
							currency={displayCurrency}
							fullDate={fullDateAsString}
							isMinPrice={isMinPriceDate}
							onClick={() =>
								onDateSelected(
									fullDateAsString,
									priceTag,
									type,
									isMinPriceDate,
								)
							}
							selectedDate={selectedDateAsString}
							hidePrice={hidePrice}
						/>
					);
				})}
		</DateComponentsWrapper>
	);

	const getFootNote = () => {
		const currencyName =
			currencyCode ||
			citiesMap?.[currentCityCode]?.country?.currency?.code ||
			'USD';
		const symbol = currenciesMap?.[currencyName]?.localSymbol || '$';

		return (
			<StyledFootNote>
				<span className='calendar-footnote'>
					*{' '}
					{strings.formatString(
						strings.HORIZONTAL_DATE_LIST.FOOTNOTE,
						`${currencyName} (${symbol})`,
					)}
				</span>
			</StyledFootNote>
		);
	};

	const getCalendarHTML = () => (
		<CalendarContainer>
			{/* @ts-expect-error TS(2769): No overload matches this call. */}
			<MonthWrapper isSecondMonth={false}>
				{/* @ts-expect-error TS(2769): No overload matches this call. */}
				<TopBar isSingleMonthCalendar={true} isDesktop={isDesktop}>
					{getMonthHeader(false)}
					{getDaysDiv()}
				</TopBar>
				<CalendarBodyWrapper className='calendar-body-wrapper'>
					<CalendarBody
						// @ts-expect-error TS(2769): No overload matches this call.
						isSecondMonth={false}
						isSingleMonthCalendar={true}
						isDesktop={isDesktop}
					>
						{getDateComponents(false)}
					</CalendarBody>
				</CalendarBodyWrapper>
			</MonthWrapper>
		</CalendarContainer>
	);

	useEffect(() => {
		const months = [...inventoryListsMap.keys()];

		if (selectedDate) {
			const currSelectedMonth = formatInMonthTitleFormat(selectedDate);
			const selectedDateMonthIndex = months.indexOf(currSelectedMonth);
			let currMonthIndex =
				selectedDateMonthIndex >= 0 ? selectedDateMonthIndex : 0;
			let nextMonthIndex =
				currMonthIndex > months.length - 1 ? null : currMonthIndex + 1;
			//If users selects last month, show nextMonth as currentMonth
			if (nextMonthIndex === null && months.length > 1) {
				nextMonthIndex = currMonthIndex;
				currMonthIndex -= 1;
			}
			setCurrVisibleMonth(
				currMonthIndex !== null ? months[currMonthIndex] : null,
			);
			setNextVisibleMonth(
				nextMonthIndex !== null ? months[nextMonthIndex] : null,
			);
		} else {
			setCurrVisibleMonth(months[0]);
			setNextVisibleMonth(months.length > 1 ? months[1] : null);
		}
	}, [selectedDate, inventoryListsMap]);

	useEffect(() => {
		sendCalendarOpenedEvent(id, type);
	}, [id, sendCalendarOpenedEvent, type]);

	if (!currVisibleMonth)
		return (
			<DummyCalendar
				selfRef={selfRef}
				type={type}
				inventoryListsMap={inventoryListsMap}
				wrapperClass={wrapperClass}
				allowPageScroll={isStaticPositioned}
			/>
		);
	const getCalendarWrapper = () => (
		<CalendarWrapper
			ref={selfRef}
			className={wrapperClass}
			// @ts-expect-error TS(2769): No overload matches this call.
			isDesktop={isDesktop}
		>
			{getCalendarHTML()}
			{getFootNote()}
		</CalendarWrapper>
	);
	if (isStaticPositioned) return getCalendarWrapper();

	return (
		<ModalWrapper>
			<Modal
				open={true}
				containerClassName={'calendar-modal-container'}
				contentClassName={'calendar-content-wrapper'}
			>
				{getCalendarWrapper()}
			</Modal>
		</ModalWrapper>
	);
};

const mapStateToProps = (state: any) => {
	return {
		currenciesMap: getCurrenciesMap(state),
		currencyCode: getCurrentCurrency(state),
		currentCityCode: getCurrentCityCode(state),
		citiesMap: getCitiesMap(state),
	};
};

export default connect(mapStateToProps)(RegularCalendarForBroadway);

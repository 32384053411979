import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
/* eslint-disable-next-line no-restricted-imports */
import styled, { css } from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import Conditional from 'Components/common/conditional';
import LSpan from 'Components/common/localizedTags/localizedSpan';

import { PromoIcon } from 'Assets/svg/promoIcon';

import { formatPrice } from 'Utils/gen';
import { getCurrenciesMap } from 'Utils/stateUtils';

import { strings } from 'Constants/strings';

/* Colors, Labels, Constants */
import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

const selectedAndHoveredDate = css`
	border-radius: 0.25rem;
	background-color: ${colors.PURPS_LEVEL[10]};

	.date-label span,
	.price {
		color: ${colors.PURPS};

		&.min-price {
			color: ${colors.PURPS};
			background: ${colors.WHITE};
		}
	}
`;

export const DateBigWrapper = styled.div`
	text-align: center;
	opacity: 1;
	display: flex;
	flex-direction: column;
	width: 3.75rem;
	height: 3.75rem;
	box-shadow: none;
	cursor: pointer;
	transition: all 0.6 ease;

	.date-label {
		margin-top: 0.8125rem;
		margin-bottom: 0.125rem;
		display: flex;
		flex-direction: row-reverse;
		justify-content: center;
		svg {
			margin-left: 1px;
			height: 10px;
			width: 10px;
		}
	}

	.date-label span {
		${getFontDetailsByLabel(TYPE_LABELS.SUBHEADING_LARGE)};
		color: ${colors.GREY_DS.G2};
	}

	${props =>
		(props as any).isSeatMap &&
		`justify-content: center;
    margin-bottom: 0.3125rem;`}

	&.empty {
		pointer-events: none;
		outline: none;
		border: none;
		cursor: auto;
	}

	&.unavailable {
		pointer-events: none;
		cursor: auto;

		.date-label span {
			color: ${colors.GREY.C4};
		}
	}

	&.selected-date {
		${selectedAndHoveredDate}
	}

	@media (min-width: 768px) {
		&:hover {
			${selectedAndHoveredDate}
		}
	}

	${({
		// @ts-expect-error TS(2339): Property 'isSvgOrSeatmap' does not exist on type '... Remove this comment to see the full error message
		isSvgOrSeatmap,
	}) =>
		isSvgOrSeatmap &&
		`
        width: 5rem;
        height: 5rem;
        margin: 0.625rem;
        box-shadow: 0 0.3125rem 0.9375rem 0 rgb(0 0 0 / 10%);

        .date-label {
            margin-top: 0.5rem;
        }

        .date-label span{
            ${getFontDetailsByLabel(TYPE_LABELS.HEADING_REGULAR)};
        }

        &.empty {
            box-shadow: none;
        }

        &:hover {
            background-color: #f2f2f2;
            box-shadow: 0 1px 5px 0 rgb(0 0 0 / 30%);

            .date-label span,
            .price {
                color: ${colors.GREY_DS.G2};

				&.min-price {
					color: ${colors.GREY_DS.G2};
				}
			}
		}
	`};

	@media (max-width: 768px) {
		width: 3rem;
		height: 3.625rem;

		.date-label {
			margin-top: 0.75rem;

			span {
				${getFontDetailsByLabel(TYPE_LABELS.SUBHEADING_LARGE)};
			}
		}

		${({
			// @ts-expect-error TS(2339): Property 'isSvgOrSeatmap' does not exist on type '... Remove this comment to see the full error message
			isSvgOrSeatmap,
		}) =>
			isSvgOrSeatmap &&
			`
			width: 14.28571%;
			margin: 0;
			box-shadow: none;
		`};
	}
`;

const PriceWrapper = styled.div`
	width: 100%;
	margin-top: -0.125rem;
	margin-bottom: 0.8125rem;
	.sold-out {
		${getFontDetailsByLabel(TYPE_LABELS.MISC_TAG_REGULAR)};
		color: ${colors.GREY_DS.G4a};
		text-transform: capitalize;
	}

	.cut-price {
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_SMALL_HEAVY)};
		color: ${colors.GREY_DS.G4};
		text-decoration: line-through;
	}

	${props =>
		(props as any).unavailable &&
		`span {
				display: none;
		
			}`}

	@media (max-width: 768px) {
		line-height: 0.875rem;
	}
`;

const Price = styled.div`
	display: inline-block;
	margin-top: 0.125rem;

	.price {
		${getFontDetailsByLabel(TYPE_LABELS.MISC_TAG_REGULAR)};
		color: ${colors.GREY_DS.G3};
		word-wrap: break-word;
		padding: 0 0.0625rem;

		&.min-price {
			color: ${colors.OKAY_GREEN_DARK_TONE};
			background: ${colors.SOOTHING_GREEN};
			border-radius: 0.125rem;
			padding: 0.0625rem;
		}
	}

	.faded-price::after {
		content: attr(faded-chars);
		opacity: 0.4;
	}

	${({
		// @ts-expect-error TS(2339): Property 'isSvgOrSeatmap' does not exist on type '... Remove this comment to see the full error message
		isSvgOrSeatmap,
	}) =>
		isSvgOrSeatmap &&
		`
		.price {
			${getFontDetailsByLabel(TYPE_LABELS.SUBHEADING_REGULAR)};
		}
		`};

	@media (max-width: 768px) {
		.price {
			${getFontDetailsByLabel(TYPE_LABELS.MISC_BADGE_SMALL)};
		}

		.faded-price {
			font-size: 0.5625rem;
		}
	}
`;

interface DateBigProps {
	dateAsString?: string;
	cutPrice?: string;
	price?: string;
	isAvailable?: boolean;
	isEmpty?: boolean;
	isMinPrice?: boolean;
	onClick?: (...args: any[]) => any;
	isSvgOrSeatMap?: boolean;
	fullDate?: string;
	selectedDate?: string;
	hidePrice?: boolean;
	highlightSelectedDate?: boolean;
}

class DateBig extends React.Component<DateBigProps, {}> {
	onClick = () => {
		const { onClick } = this.props;
		if (onClick) onClick();
	};

	getPriceDiv = () => {
		const {
			price,
			cutPrice,
			isAvailable,
			isMinPrice,
			// @ts-expect-error TS(2339): Property 'isSvgOrSeatmap' does not exist on type '... Remove this comment to see the full error message
			isSvgOrSeatmap,
		} = this.props;

		const cutPriceDiv = cutPrice ? (
			<div className='cut-price-wrapper'>
				<span className='cut-price'>{cutPrice}</span>
			</div>
		) : (
			''
		);
		return isAvailable ? (
			<PriceWrapper>
				{/* @ts-expect-error TS(2769): No overload matches this call. */}
				<Price isSvgOrSeatmap={isSvgOrSeatmap}>
					<span
						className={`price ${
							isMinPrice && price ? 'min-price' : ''
						}`}
					>
						{price}
					</span>
				</Price>
				{cutPriceDiv}
			</PriceWrapper>
		) : (
			// @ts-expect-error TS(2769): No overload matches this call.
			<PriceWrapper unavailable={!isAvailable}>
				<span className='sold-out'>
					{strings.CMN_SOLD_OUT.toLowerCase()}
				</span>
			</PriceWrapper>
		);
	};

	render() {
		const {
			dateAsString,
			isEmpty,
			isAvailable,
			selectedDate,
			fullDate,
			hidePrice,
			// @ts-expect-error TS(2339): Property 'isSvgOrSeatmap' does not exist on type '... Remove this comment to see the full error message
			isSvgOrSeatmap,
			// @ts-expect-error TS(2339): Property 'hasDiscount' does not exist on type 'Rea... Remove this comment to see the full error message
			hasDiscount,
			highlightSelectedDate = true,
		} = this.props;

		const selected = fullDate === selectedDate;
		return isEmpty ? (
			<DateBigWrapper
				// @ts-expect-error TS(2769): No overload matches this call.
				isSvgOrSeatmap={isSvgOrSeatmap}
				className={`empty`}
			/>
		) : (
			<DateBigWrapper
				// @ts-expect-error TS(2769): No overload matches this call.
				isSvgOrSeatmap={isSvgOrSeatmap}
				className={classNames({
					unavailable: !isAvailable,
					'selected-date': highlightSelectedDate && selected,
				})}
				aria-label={dateAsString}
				role='button'
				aria-hidden={!isAvailable}
				onClick={this.onClick}
			>
				<div className='date-label'>
					<Conditional if={hasDiscount && isAvailable}>
						<span className='promo-icon'>
							<PromoIcon />
						</span>
					</Conditional>
					<LSpan>{dateAsString}</LSpan>
				</div>

				<Conditional if={!hidePrice}>{this.getPriceDiv()}</Conditional>
			</DateBigWrapper>
		);
	}
}

export default DateBig;
